:root {
    --background: #f5f3f5;
    --contBack: #E6E6E4;
    --lightgrey: #C6C6C6;
    --grey: #545454;
    --black: #0E141D;
    --red: rgba(255, 49, 49, 0.17);
    --orange: #ff914d;
    --gradient: linear-gradient(left to right,var(--red), var(--orange));
    --wave: #66A5AD;
    --seaform: #C4DFE6;
    --white: whitesmoke;
}

.App {
    background-color: var(--background);
    overflow: hidden;
}

.flexbox {
    display: flex;
    justify-content: center;
    align-items: center;
}
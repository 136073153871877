.skills-container {
    background-color: var(--contBack);
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    border-radius: 10px;
    max-width: 75vw;
    margin-bottom: 10rem;
}

.skills-container h2 {
    font-size: 2.2rem;
}

.skills-container p {
    font-size: 1.4rem;
    padding: 0 4rem;
}

.circles {
    display: flex;
    list-style: none;
    width: 800px;
    overflow-x: scroll;
    gap: 0.2rem;
    padding-bottom: 1rem;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--lightgrey);
    -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
    background: var(--grey);
    -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
    background: #fff3;
}

.circle-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
}
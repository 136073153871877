.langswitcher {
    overflow: hidden;
    width: 3.3rem;
    height: 3.3rem;
}

.langswitcher button {
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.langswitcher img {
    width: 100%;
    height: 100%;

}
.circle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--contBack);
    gap: 1rem;
    padding: 0;
    margin: 0;
}

.circular-progress {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 10px 7px silver;
}

.circular-progress::before {
    content: "";
    position: absolute;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-color: var(--contBack);
}

.progress-value {
    position: relative;
    font-size: xx-large;
    font-weight: 600;
}

.circle-text {
    text-align: center;
    padding: 0;
    margin: 0;
}
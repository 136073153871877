.intro-container {
    background-color: var(--background);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.intro-first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.first-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
}

.first-container h2 {
    max-font-size: 100%;
    font-size: 4rem;
    color: #000000;
    font-weight: bold;
    white-space: nowrap;
}

.first-container p:first-child {
    font-size: 2.2rem;
    color: var(--grey);
}

.first-container p:last-child {
    font-size: 1.6rem;
    color: var(--grey);
    max-width: 30rem;
}
/*------------------------------------------*/

.intro-img img {
    width: 20rem;
    height: 20rem;
}

/*------------------------------------------*/
@media screen and (max-width: 600px) {

}


.navbar-container {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 2rem;
    background-color: transparent;
    color: var(--black);
}

.link-container * {
    margin-left: 4rem;
    font-size: 1.4rem;
    color: var(--black);
    text-decoration: none;
}

.external-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-right: 2rem;
}

.external-container * {
    font-size: 2.2rem;
}
.cv-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 2rem 0 2rem 0;
}

.cv-left, .cv-right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.cv-left {
    margin-right: 2rem;
    text-align: right;
}
/*-----------------------------*/
.seperationline {
    width: 0.5rem;
    height: 20rem;
    background-color: var(--seaform);
    border-radius: 10px;
}
/*-----------------------------*/
.cv-right {
    margin-left: 2rem;
    text-align: left;
}